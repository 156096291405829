function genURL(url, options, ...params) {
  let questionMark = '?'

  // if options is not defined it means options is taking the position of the
  // first element in the params array
  params = typeof options !== 'object' ? [options, ...params] : params

  if (options.removeQuestionMark !== undefined && options.removeQuestionMark) {
    questionMark = ''
  }

  return (paramValues) => {
    const pattern = new RegExp(
      Object.keys(paramValues).map(key => '\\$' + key + '\\$').join('|'),
      'g'
    )
    const validParams = params
      .map(param => {
        if (pattern.test(param)) {
          return param
            .replace(pattern, matched => paramValues[matched.replace(/\$/gi, '')])
        } else {
          return null
        }
      })
      .filter(param => param !== null)
      .reduce((prev, next) => `${prev}&${next}`)

    return url + (url.includes(questionMark) ? '' : questionMark) + validParams
  }
}

/**********/
/* PARAMS */
/**********/
const journalId = 'journalId=$journalId$'
const groupId = 'groupId=$groupId$'
const groupid = 'groupid=$groupId$'
const jourGroupId = 'jourGroupId=$groupId$'
const jourId = 'jourId=$journalId$' // Should be able to handle multiple comma separated ids
const pubJourId = 'ji:$journalId$'
const pg1INDIs1 = 'pg1=INDI&s1=$authorId$'
const pg1ISSIs1 = 'pg1=ISSI&s1=$issueId$'
const pg4MRs4 = 'pg4=MR&s4='
const pg4MRs4Volume = pg4MRs4 + '$volumeId$'
const pg4MRs4Paper = pg4MRs4 + '$paperId$'
const pg4JOURs4 = 'pg4=JOUR&s4=$jourAbbr$'
const aggYear = 'agg_year_$pubYear$=$pubYear$'
const drYearRangeFirst = 'dr=pubyear&yearRangeFirst=$yearRangeFirst$'
const yearRangeSecond = 'yearRangeSecond=$yearRangeSecond$'
const mrauthid = 'mrauthid=$authorId$'
const authorId = 'authorId=$authorId$'
const pc = 'pc=$msc$'
const aggPSubj = 'agg_psubj_$msc$=$msc$'
const seriesId = 'seriesId=$seriesId$'
const serId = 'serId=$seriesId$'
const caller = 'caller=$caller$'
const citedYear = 'cited_year=$pubYear$'
const citingYear = 'citing_year=$citeYear$'
const issId = 'iss_id=$issueId$'
// const pdf = 'barcode=false&cover=false&watermark=false'
const hashJ = '#j$journalId$'
const hashS = '#s$seriesId$'
const statusPaperId = 'cno=$paperId$'

/********/
/* URLs */
/********/
const profileURL = genURL(
  '/mathscinet/search/serials/profile',
  journalId,
  groupId,
  seriesId,
)

const historyURL = genURL(
  '/mathscinet/search/serials/history',
  journalId,
  groupId,
  seriesId,
)

const publicationsURL = genURL(
  '/mathscinet/search/publications.html',
  jourGroupId,
  jourId,
  serId,
  pg1INDIs1,
  pg1ISSIs1,
  pg4JOURs4,
  aggYear,
  drYearRangeFirst,
  yearRangeSecond,
  aggPSubj,
)

// const publicationsURL = genURL(
//   '/mathscinet/publications-search?query=',
//   pubJourId
// )

const authorURL = genURL(
  // '/mathscinet/search/author.html',
  // mrauthid,
  // drYearRangeFirst,
  // yearRangeSecond,
  '/mathscinet/author',
  authorId,
)

// No longer in use.
// const mscURL = genURL(
//   '/mathscinet/search/mscbrowse.html',
//   pc,
//   jourGroupId,
//   seriesId,
//   drYearRangeFirst,
//   yearRangeSecond,
// )

const citationsURL = genURL(
  '/mathscinet/search/publications.html',
  caller,
  groupid,
  seriesId,
  citedYear,
  citingYear,
)

const recentURL = genURL(
  '/mathscinet/search/issues.html',
  groupid,
)

const serialsIssuesURL = genURL(
  '/mathscinet/search/serials/issues',
  journalId,
  groupId,
)

const serialsVolumesURL = genURL(
  '/mathscinet/search/serials/volumes',
  seriesId,
)

const documentURL = genURL(
  '/mathscinet/search/publdoc.html',
  pg4MRs4Volume,
  pg4MRs4Paper,
)

const issueStatusURL = genURL(
  '/mason/issStatHist',
  issId,
)

const paperStatusUrl = genURL(
  '/paperstatus/paperstatus.html',
  statusPaperId,
)

const spineURL = function(params) {
  // has parameters in the URL section, not the queryString, so genURL doesn't work',
  return '/imageServer/scan/issue/pdf/' + params.recdNum + '/' + params.recdNum + '.pdf?barcode=false&cover=false&watermark=false'
}
// const spineURL = genURL(
//   // 'http://john.mr.ams.org:2610/si/journals/',
//   '/imageServer/scan/issue/pdf/$recdNum$/$recdNum$.pdf?barcode=false&cover=false&watermark=false',
//   pdf,
// )

function processYearRange(params) {
  if (params.yearRange && params.yearRange.length === 2) {
    params.yearRangeFirst = params.yearRange[0]
    params.yearRangeSecond = params.yearRange[1]
  }

  return params
}

export default {
  profile: (params) => {
    if (params.authorId) {
      return authorURL(params)
    }

    return profileURL(params)
  },
  history: (params) => historyURL(params),
  historySeq: (params) => {
    const url = window.location.pathname + window.location.search
    const historySeqURL = genURL(
      url,
      {
        removeQuestionMark: true,
      },
      hashJ,
      hashS,
    )

    return historySeqURL(params)
  },
  publications: (params) => {
    params = processYearRange(params)
    if (params.volumeId || params.paperId) {
      return documentURL(params)
    }

    return publicationsURL(params)
  },
  author: (params) => {
    params = processYearRange(params)
    return authorURL(params)
  },
  // msc: (params) => {
  //   params = processYearRange(params)

  //   if (params.groupId || params.seriesId) {
  //     return publicationsURL(params)
  //   }

  //   return mscURL(params)
  // },
  citations: (params) => {
    params.caller = 'mrcit'
    return citationsURL(params)
  },
  recent: (params) => recentURL(params),
  voliss: (params) => {
    return params.seriesId ? serialsVolumesURL(params) : serialsIssuesURL(params)
  },
  document: (params) => {
    if (params.issueId) {
      return publicationsURL(params)
    }

    return documentURL(params)
  },
  status: (params) => {
    if (params.issueId) {
      return issueStatusURL(params)
    }
    if (params.paperId) {
      return paperStatusUrl(params)
    }
  },
  spine: (params) => spineURL(params),
}
