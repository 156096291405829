import { getResult } from '../utils/api'
import { checkInt, checkArrayOfInts } from '../utils/type-validation'

export default {
  async details(groupId) {
    checkInt(groupId, 'groupId')
    const result = await getResult('/api/journal/details', { groupId })
    return result.details
  },

  // async historySummary(groupId) {
  //   checkInt(groupId, 'groupId')
  //   return getResult('/api/journal/historySummary', { groupId })
  // },

  // async getRecentIssue(groupId) {
  //   checkInt(groupId, 'groupId')
  //   const result = await getResult('/api/journal/recentIssues', { groupId })
  //   return result.details
  // },

  async issues(groupId) {
    checkInt(groupId, 'groupId')
    const result = await getResult('/api/journal/issues', { groupId })
    return result.details
  },

  async related(journalId) {
    checkInt(journalId, 'journalId')
    const result = await getResult('/api/journal/related', { journalId })

    return result.journals
  },

  async mcq(groupId) {
    checkInt(groupId, 'groupId')
    return getResult('/api/journal/mcq', { groupId })
  },

  async publications(groupId) {
    checkInt(groupId, 'groupId')
    const result = await getResult('/api/journal/publications', { groupId })

    return result.journals
  },

  async classifications(groupId) {
    checkInt(groupId, 'groupId')
    const result = await getResult('/api/journal/classifications', { groupId })

    return result.journals
  },

  async authors(groupId) {
    checkInt(groupId, 'groupId')
    const result = await getResult('/api/journal/authors', { groupId })

    return result.journals
  },

  async citations(groupId, year) {
    checkInt(groupId, 'groupId')
    try { checkInt(year) } catch (e) { year = '' }

    const result = await getResult('/api/journal/citations', {
      groupId,
      year,
    })

    return result.journals
  },

  async topCiters(groupId, year) {
    checkInt(groupId, 'groupId')
    try { checkInt(year) } catch (e) { year = '' }

    const result = await getResult('/api/journal/topCiters', {
      groupId,
      year,
    })

    return result.journals
  },

  // async duration(journalId) {
  //   checkInt(journalId, 'journalId')
  //   return getResult('/api/journal/duration', { journalId })
  // },

  async pre85Year(jourAbbr) {
    return getResult('/api/journal/pre85Years', { jourAbbr })
  },

  async getGroupId(journalId) {
    checkInt(journalId, 'journalId')
    const result = await getResult('/api/journal/getgroupid', { journalId })
    return result.details.length > 0 ? result.details[0].groupId : -1
  },

  async getGroupIds(journalIds) {
    checkArrayOfInts(journalIds, 'journalIds')
    const result = await getResult('/api/journal/getgroupid', {
      journalId: journalIds.join(','),
    })
    return result.details
  },

  async internalNotes(journalIds) {
    checkArrayOfInts(journalIds, 'journalIds')
    const result = await getResult('/api/journal/internalNotes', {
      journalId: journalIds.join(','),
    })
    return result.journals
  },
}
