import Vue from 'vue'

const http = Vue.prototype.$http

export async function getResult(url, params) {
  const { data } = await http.get(url, {
    params,
  })

  return data
}
