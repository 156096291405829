export function checkInt(value, varName) {
  if (isNaN(parseInt(value))) {
    throw new TypeError(`${varName} is not a number`)
  }
}

export function checkArrayOfInts(array, varName) {
  if (Array.isArray(array)) {
    array.forEach((v, i) => checkInt(v, `${varName}[${i}]`))
  } else {
    throw new TypeError(`${varName} is not an array`)
  }
}
