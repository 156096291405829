import URLGen from './utils/url-gen'

const defaultLanguage = navigator.languages ? navigator.languages[0] : navigator.language
let formatter = Intl ? new Intl.NumberFormat(defaultLanguage) : undefined

// It is important to create only one instance of Intl.NumberFormat because it is
// expensive
export const formatNumber = (number) => {
  const language = navigator.languages ? navigator.languages[0] : navigator.language

  // if language changed create a new formatter
  if (defaultLanguage !== language) {
    formatter = Intl ? new Intl.NumberFormat(language) : undefined
  }

  return formatter ? formatter.format(number) : number
}

export const titleCase = (name) => {
  return name[0].toUpperCase() + name.substr(1)
}

export const uniqueArray = (array) => {
  if (array === null || array === undefined || array.length === 0) {
    return []
  }
  return array.filter((value, index, self) => { return self.indexOf(value) === index })
}

export const formatName = (name, removeMathJax) => {
  if (removeMathJax === undefined) {
    removeMathJax = false
  }
  if (Array.isArray(name)) {
    const nameArray = []
    for (const i in name) {
      nameArray.push(formatName(name[i], removeMathJax))
    }
    return nameArray
  }

  const replaceChars = ['\\asup', '\\ ', '\\&']
  let containsMore = false
  do {
    // Superscript
    const asupIndex = name.indexOf('\\asup')
    if (asupIndex > -1) {
      const before = name.substr(0, asupIndex)
      let sup = ''
      if (name[asupIndex + 5] === ' ') {
        sup = name[asupIndex + 6]
      } else {
        sup = name.substr(name.indexOf('{') + 1, name.indexOf('}') - name.indexOf('{') - 1)
      }
      name = before + '<sup>' + sup + '</sup>'
    }

    // Escaped space
    if (name.indexOf('\\ ') > -1) {
      name = name.replace('\\ ', ' ')
    }

    // Escaped &
    if (name.indexOf('\\&') > -1) {
      name = name.replace('\\&', '&')
    }

    containsMore = false
    for (const i in replaceChars) {
      if (name.indexOf(replaceChars[i]) > -1) {
        containsMore = true
        break
      }
    }
  } while (containsMore)

  if (removeMathJax) {
    while (name.indexOf('$') > -1) {
      name = name.replace('$', '')
    }
  }
  return name
}

export const simplifyUrl = (url) => {
  const simpleUrl = url.replace('http://', '').replace('https://', '').replace('www.', '')
  if (simpleUrl.indexOf('/') > 0) {
    return simpleUrl.substr(0, simpleUrl.indexOf('/'))
  }
  return simpleUrl
}

export const formatIssueStr = (data) => {
  let formatted = ''
  if ('year' in data && data.year != null && data.year.length > 0) {
    formatted += data.year
  }
  if ('volume' in data && data.volume != null && data.volume.length > 0) {
    if (formatted.length > 0) {
      formatted += ', '
    }
    formatted += 'vol. ' + data.volume
  }
  if ('issNum' in data && data.issNum != null && data.issNum.length > 0) {
    if (formatted.length > 0) {
      formatted += ', '
    }
    formatted += 'no. ' + data.issNum
  }
  if ('multIssNum' in data && data.multIssNum !== null && data.multIssNum.length > 0) {
    formatted += '-' + data.multIssNum
  }
  if ('issName' in data && data.issName != null && data.issName.length > 0) {
    if (formatted.length > 0) {
      formatted += ', '
    }
    formatted += data.issName
  }
  return formatted
}

export const formatSerialLink = (name, id, serialType) => {
  const title = ''
  return title + "<a href='profile?" + serialType + 'Id=' + id + "' class='min-accessibility-spacing' title='View profile for " + name + "'>" + name + '</a>'
}

export const toTitleCase = (str) => {
  if ((str === null) || (str === '')) {
    return false
  } else {
    str = str.toString()
  }

  return str.replace(/\w\S*/g, function(txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() })
}
export const toSnakeFormat = (text) => {
  return text.toLowerCase().replace(/\s+/g, '_')
}

export const generateUniqueId = () => {
  return '_' + Math.random().toString(36).substr(2, 9)
}

const easeInOutQuad = (t) => { return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t }

const scrollTo = (el, start, end) => (p) => {
  const next = start + p * end
  el.scrollTo(0, next)
}

const animate = (render, duration, easing, cb) => {
  const start = Date.now()

  const loop = () => {
    const p = (Date.now() - start) / duration
    if (p > 1) {
      render(1)
      if (cb) cb()
    } else {
      requestAnimationFrame(loop)
      render(easing(p))
    }
  }

  loop()
}

export const animatedScrollTo = (end, container, opts) => {
  const options = opts || {}
  const duration = options.duration ? options.duration : 350
  const cb = options.completed
  const start = container.scrollTop
  animate(scrollTo(container, start, end), duration, easeInOutQuad, cb)
}

export const flatten = (array) => {
  return array.reduce((a, b) => [...a, ...b], [])
}

export const genRelatedTable = (related, showSequential, showMergeSplit, router) => {
  const sequentialTypes = ['Continued as', 'Formerly']
  const splitMergeTypes = ['Merged from', 'Merged into', 'Split into', 'Split from']
  const byType = {}
  for (const i in related) {
    if ('relsee' in related[i] && related[i].relsee != null) {
      related[i].reltype = related[i].relsee
    }

    if (!showSequential && sequentialTypes.indexOf(related[i].reltype) > -1) {
      continue
    }
    if (!showMergeSplit && splitMergeTypes.indexOf(related[i].reltype) > -1) {
      continue
    }
    if (!(related[i].reltype in byType)) {
      byType[related[i].reltype] = []
    }
    byType[related[i].reltype].push(related[i])
  }

  const table = []
  const order = Object.keys(byType).sort()
  for (const i in order) {
    if (order[i] === null || order[i] === 'null') {
      continue
    }
    const row = [order[i], []]
    const usedAbbr = []
    // console.log(byType[order[i]])
    for (const s in byType[order[i]]) {
      const serial = byType[order[i]][s]
      // Avoid duplicate abbreviations
      if (usedAbbr.indexOf(serial.relabbrev) > -1) {
        continue
      } else {
        usedAbbr.push(serial.relabbrev)
      }

      const idType = serial.relkey === 'SER' ? 'series' : 'journal'

      const toTarget = {
        name: 'SerialProfile',
        query: {
          [idType + 'Id']: serial.relid,
        },
      }
      // let target = URLGen.profile
      if (sequentialTypes.indexOf(serial.reltype) > -1) {
        // target = URLGen.historySeq
        toTarget.name = 'History'
      }

      // const genInfo = {}
      // if (serial.relkey === 'SER') {
      //   genInfo.seriesId = serial.relid
      // } else {
      //   genInfo.journalId = serial.relid
      // }
      const seriesEntry = {
        text: serial.relabbrev,
        // url: target(genInfo),
        // to: toTarget,
        url: router.resolve(toTarget).href,
        target: '_self',
      }
      if (toTarget.name === 'SerialProfile') {
        seriesEntry.attr = {
          title: 'View profile for ' + serial.relabbrev,
          class: 'min-accessibility-spacing',
        }
      }
      // if (target === URLGen.profile) {
      //   seriesEntry.attr = {
      //     title: 'View profile for ' + serial.relabbrev,
      //   }
      // } else {
      //   seriesEntry.target = ''
      // }

      row[1].push(seriesEntry)
    }
    table.push(row)
  }
  return table
}

export const dateSort = (a, b) => {
  if (typeof (a) === 'object') {
    a = a.date
  }
  if (typeof (b) === 'object') {
    b = b.date
  }
  if (a === 'N/A' || b === 'N/A') {
    if (a === b) {
      return 0
    } else {
      if (b !== 'N/A') {
        return 1
      } else {
        return -1
      }
    }
  }
  const bSplit = b.split('-').map(Number)
  const aSplit = a.split('-').map(Number)
  for (const i in bSplit) {
    if (bSplit[i] !== aSplit[i]) {
      return bSplit[i] - aSplit[i]
    }
  }
  return 0
}

export const serialDetailsSort = (a, b) => {
  // Sort entries that come back from /mathscinet/api/journal/details?groupId=<number>
  // Test Cases
  // /mathscinet/search/serials/profile?journalId=3544 (Adv. Math. started before Adv. in Math., but is more current)
  // /mathscinet/search/journal/profile?groupId=3490 (Temporarily had NA for both oldest and newest for the current)
  const aNewerThanB = 1
  const aOlderThanB = -1
  if (a.related !== undefined && a.related.length > 0) {
    const match = a.related.filter(x => x.relid === b.jourId || x.relid === b.serId)
    if (match.length > 0) {
      // Determine the relationship
      const reltype = match[0].reltype
      if (reltype === 'Formerly') {
        return aNewerThanB
      }
      if (reltype === 'Continued as') {
        return aOlderThanB
      }
      // Not sure how to handle 'Merged into', 'Merged from', 'Split into', 'Split from'
    }
  } else {
    // Sort by dates
    // If either Newest Date is N/A, but has a Oldest date, then it's current (newest) journal
    // If both Newest Date and Oldest Date is N/A, it's oldest (papers/issues haven't been processed)
    const aOldest = a.oldest.length > 0 ? parseInt(a.oldest[0].year) : 'N/A'
    const bOldest = b.oldest.length > 0 ? parseInt(b.oldest[0].year) : 'N/A'
    const aNewest = a.newest.length > 0 ? parseInt(a.newest[0].year) : 'N/A'
    const bNewest = b.newest.length > 0 ? parseInt(b.newest[0].year) : 'N/A'

    if (aNewest === 'N/A' || bNewest === 'N/A') {
      if (aOldest === 'N/A') {
        return aOlderThanB
      }
      if (bOldest === 'N/A') {
        return aNewerThanB
      }
    }

    if (aNewest === bNewest) { // Works when both are N/A as well as if both are the same year
      // Note: Which is considered "oldest" might want to change. Currently considers the one that started longer ago as the more historic one
      if (aOldest < bOldest) {
        return aOlderThanB
      }
      if (aOldest > bOldest) {
        return aNewerThanB
      }
      return 0
    }

    if (aNewest > bNewest) {
      return aNewerThanB
    }
    if (aNewest < bNewest) {
      return aOlderThanB
    }
  }
  return 0
}

export const copyStringToClipboard = (str) => {
  // Create new element
  const el = document.createElement('textarea')
  // Set value (string to be copied)
  el.value = str
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '')
  el.style = { position: 'absolute', left: '-9999px' }
  document.body.appendChild(el)
  // Select text inside element
  el.select()
  // Copy text to clipboard
  document.execCommand('copy')
  // Remove temporary element
  document.body.removeChild(el)
}

export const popupLink = (url) => {
  const splitUrl = url.split('=')
  const name = splitUrl[splitUrl.length - 1]
  const options = 'width=400,height=400,top=((screen.height - height) / 2),left=((screen.width - width) / 2)'
  const jsLink = 'javascript:window.open("' + url + '", "' + name + '", "' + options + '")'
  return jsLink
}

export const parseParams = (querystring) => {
  const results = {
    journalId: -1,
    groupId: -1,
    seriesId: -1,
    showInternalNotes: false,
  }
  try {
    const params = new URLSearchParams(querystring)
    if (params.has('groupId') && params.get('groupId').length > 0) {
      results.groupId = parseInt(params.get('groupId'))
    }
    if (params.has('journalId') && params.get('journalId').length > 0) {
      results.journalId = parseInt(params.get('journalId'))
    } else if (params.has('jourId')) {
      results.journalId = parseInt(params.get('jourId'))
    }
    if (params.has('seriesId') && params.get('seriesId').length > 0) {
      results.seriesId = parseInt(params.get('seriesId'))
    }
    if (params.has('showNotes') && params.get('showNotes').toLowerCase() === 'true') {
      results.showInternalNotes = true
    }
  } catch (err) {
    // Fallback for IE and maybe Firefox <= 43
    const params = {}
    const tempParams = querystring.split('&')
    for (const i in tempParams) {
      const pair = tempParams[i].split('=')
      if (!(pair[0] in params)) {
        params[pair[0]] = pair[1]
      } else {
        if (Array.isArray(params[pair[0]])) {
          params[pair[0]].push(pair[1])
        } else {
          params[pair[0]] = [
            params[pair[0]],
            pair[1],
          ]
        }
      }
    }
    if ('groupId' in params && params.groupId.length > 0) {
      results.groupId = parseInt(params.groupId)
    }
    if ('journalId' in params || 'jourId' in params) {
      if (params.journalId.length > 0) {
        results.journalId = parseInt(params.journalId)
      }

      if (results.journalId === undefined && params.jourId.length > 0) {
        results.journalId = parseInt(params.jourId)
      }
    }
    if ('seriesId' in params && params.seriesId.length > 0) {
      results.seriesId = parseInt(params.seriesId)
    }
    if ('showNotes' in params && params.showNotes.toLowerCase() === 'true') {
      results.showInternalNotes = true
    }
  }
  return results
}

export const volIssTable = (rawVolIss, isSeries, limit, journalId) => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const oldestPendingYear = currentYear - 1

  const table = []

  for (const i in rawVolIss) {
    if (limit && i >= limit) {
      continue
    }
    // Remove issues that cover pending papers
    if (rawVolIss[i].year == null) {
      continue
    }

    if (isSeries) {
      let volText = ''
      if (rawVolIss[i].volume) {
        if (rawVolIss[i].volume.toLowerCase().indexOf('vol') === -1) {
          volText = `vol. ${rawVolIss[i].volume}, `
        } else {
          volText = ` ${rawVolIss[i].volume}, `
        }
      }
      table.push({
        id: `item_${rawVolIss[i].year}${rawVolIss[i].volume}${rawVolIss[i].bookId}${rawVolIss[i].paperId}`,
        text: `${rawVolIss[i].year}, ${volText}${rawVolIss[i].title}`,
        to: {
          name: 'ArticlePage',
          query: {
            articleId: rawVolIss[i].paperId,
          },
        },
        pending: rawVolIss[i].year >= oldestPendingYear,
        statusUrl: popupLink(URLGen.status({ paperId: rawVolIss[i].paperId })),
        hasSpine: false,
        spineUrl: null,
      })
    } else {
      // let url = URLGen.publications({ issueId: rawVolIss[i].issid })
      let toDest
      if (rawVolIss[i].public) {
        if (rawVolIss[i].journalId) {
          journalId = rawVolIss[i].journalId
        }
        toDest = {
          name: 'PublicationsSearch',
          query: {
            query: `ji:${journalId} ` + formatPublicationSearchQuery(rawVolIss[i]), // Need to add a Journal ID
          },
        }
      }

      const itemText = formatIssueStr(rawVolIss[i])
      const itemId = itemText.trim()
        .replace(/[,\.]/gi, '')
        .toLowerCase()
        .replace(/ /g, '')

      table.push({
        id: `item_${itemId}`,
        text: formatIssueStr(rawVolIss[i]),
        to: toDest,
        pending: rawVolIss[i].year >= oldestPendingYear,
        statusUrl: popupLink(URLGen.status({ issueId: rawVolIss[i].issid })),
        hasSpine: !!rawVolIss[i].spineScan,
        spineUrl: URLGen.spine({ recdNum: rawVolIss[i].recdNum }),
      })
    }
  }
  return table
}

export const formatPublicationSearchQuery = (data) => {
  let formatted = ''
  // NOTE: Does not have the Journal/Series ID, that needs to be added by other sections of code
  if ('year' in data && data.year != null && data.year.length > 0) {
    formatted += `y:${data.year} `
  }
  if ('volume' in data && data.volume != null && data.volume.length > 0) {
    formatted += `v:${data.volume} `
  }
  if ('issNum' in data && data.issNum != null && data.issNum.length > 0) {
    formatted += `iss:${data.issNum}`
  }
  if ('multIssNum' in data && data.multIssNum !== null && data.multIssNum.length > 0) {
    formatted += '-' + data.multIssNum
  }
  // if ('issName' in data && data.issName != null && data.issName.length > 0) {
  //   if (formatted.length > 0) {
  //     formatted += ', '
  //   }
  //   formatted += data.issName
  // }
  if ('title' in data && data.title != null && data.title.length > 0) {
    if (formatted.length > 0 && formatted.charAt(formatted.length - 1) !== ' ') {
      formatted += ' '
    }
    formatted += `t:"${data.title}" `
  }
  return formatted
}

export const genUnassigned = (data) => {
  if (data) {
    if (Array.isArray(data)) {
      data = data[0]
    }
    const returnData = {
      text: data.issName ? data.issName : 'Unassigned Papers',
      url: URLGen.publications({ issueId: data.issid }),
    }
    return returnData
  } else {
    return null
  }
}

// NOTE: You're probably looking for utils/utils.ts
// export const parseReview = (text) => {
//   // Convert the formatting of the review into HTML
//   const parPattern = /\\par\s/gmi
//   text = text.replaceAll(parPattern, '<br><span class="ml-3"/>')

//   const quotePattern = /``([^]*?)''/gmi // The [^] is needed to handle \n in the capture group
//   text = text.replaceAll(quotePattern, '<q>$1</q>')

//   text = text.replaceAll('\\ref[', '[')
//   const mrNumPattern = /\\refmr\sMR(\d+)(.*?)\\endrefmr]/gmi
//   text = text.replaceAll(mrNumPattern, '<a href="/mathscinet/article?articleId=$1"><span class="MR">MR</span>$1</a>]')

//   text = text.replaceAll('\\$', '$')
//   text = text.replaceAll('\\%', '%')
//   text = text.replaceAll('@-', '-')
//   text = text.replaceAll('---', '&mdash;')
//   text = text.replaceAll('--', '&ndash;')
//   text = text.replaceAll('~', '&nbsp;')
//   return text
// }
