import { Route } from 'vue-router'
import { SearchParams, PublicSearchParams } from '@/global-types'

export const parseReview = (text: string): string => {
  if (!text) return ''

  // Most (if not all) should be moved to the backend to avoid conflicts with math sections
  const parPattern = /\\par\s/gmi
  const tparPattern = /\\tpar\s/gmi
  // const quotePattern = /``([^]*?)''/gmis // The [^] is needed to handle \n in the capture group
  const mrNumPattern = /\\refmr\s[MR]*(\d+)(.*?)\\endrefmr/gmis
  const refPattern = /\\ref\[(.[^\]]+)\]/gmis
  const mrHyphen = /\\mhy\s?/gmis

  // Convert the formatting of the review into HTML
  // text = text.replaceAll('&', '&amp;')
  // text = text.replaceAll('<', '&lt;')
  // text = text.replaceAll('>', '&gt;')
  text = text.replaceAll(mrHyphen, '-')
  text = text.replaceAll(parPattern, '<br><span class="ml-3"/>')
  text = text.replaceAll(tparPattern, '<br><span class="ml-3"/>')
  // text = text.replaceAll(quotePattern, '<q>$1</q>')
  text = text.replaceAll(refPattern, '[$1]')
  text = text.replaceAll(mrNumPattern, '<a href="#" onclick="goToArticle($1); return false;"><span class="MR">MR</span>$1</a>') // Was .replace()

  text = text.replaceAll('\\$', '$')
  text = text.replaceAll('\\%', '%')
  text = text.replaceAll('@-', '-')
  text = text.replaceAll('---', '&mdash;')
  text = text.replaceAll('--', '&ndash;')
  text = text.replaceAll('~', '&nbsp;')
  text = text.replaceAll('\\break', '')
  //  text = text.replaceAll('\\{', '')
  //  text = text.replaceAll('\\}', '')
  // text = text.replaceAll(/{For the text of (.[^,]*), see(.[^}]*)}/gmi, '<div class="text-center">For the text of $1, see $2</div>')
  return text.trim()
}

export const addLastDot = (text: string, stopIfEndsWith?: RegExp): string => {
  if (!text) return ''

  if (stopIfEndsWith && stopIfEndsWith.test(text)) return text

  if (text.lastIndexOf('.') !== text.length - 1) {
    text += '.'
  }

  return text.trim()
}

export const prevReviewSplitter = (text: string): string[] => {
  // \prevrevtext to \endprevrevtext is the old review text, and should be in it's own tab.
  // Expected format: \prevrevtext Super Cool Review \prevrev Reviewer Name \endprevrevtext
  // Sometimes the \prevrev is missing
  // MR4248721, MR2161884, MR4359935
  const previousReviewPattern = /\\prevrevtext([^]*?)\\endprevrevtext/gmi
  const previousReviews = text.match(previousReviewPattern)
  if (previousReviews) {
    // \prevrevr is previous reviewer. There's no ending format, it just shares the \endprevrevtext
    const prevRevIndex = text.indexOf(previousReviews[0])
    const currentReviewText = text.substring(0, prevRevIndex)
    let previousReviewText = previousReviews[0]

    let reviewerText = '' // Empty, incase there is no previous reviewer
    if (previousReviewText.indexOf('\\prevrevr ') > -1) {
      const prevReviewer = previousReviewText.substring(previousReviewText.indexOf('\\prevrevr ') + '\\prevrevr '.length, previousReviewText.indexOf('\\endprevrevtext'))
      previousReviewText = previousReviewText.substring('\\prevrevtext'.length, previousReviewText.indexOf('\\prevrevr '))
      reviewerText = `<div class="mt-3"><strong>Reviewer: </strong>${prevReviewer}</div>`
    } else {
      previousReviewText = previousReviewText.substring('\\prevrevtext'.length, previousReviewText.indexOf('\\endprevrevtext'))
    }

    return [currentReviewText, `${previousReviewText}<br>${reviewerText}`]
  }
  return [text]
}

// Add leading zeros if needed
export const addLeadingZeros = (mr: string, numberLength = 7): string => {
  return '0'.repeat(numberLength - mr.length) + mr
}

export function scrollTo(selector: string | Element, block: ScrollLogicalPosition | undefined = 'center'): Element | null {
  let element: Element | null = null

  if (typeof selector === 'object') {
    element = selector
  } else {
    element = document.querySelector(selector)
  }

  if (element && element.scrollIntoView) {
    element.scrollIntoView({
      behavior: 'smooth',
      block,
    })
  }

  return element
}

export function isNumber(str: string): boolean {
  if (typeof str !== 'string') {
    return false
  }

  if (str.trim() === '') {
    return false
  }

  return !Number.isNaN(Number(str))
}

export function updateMathJax(): void {
  if (window.MathJax) {
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub])
  }
}

export function updateSearchParams(route: Route, searchParams: SearchParams): SearchParams {
  const query = route.query.query
    ? route.query.query.toString().trim()
    : searchParams.query

  const pageNumber = route.query.page && route.query.page.toString().trim() !== '0'
    ? parseInt(route.query.page as string)
    : searchParams.pageNumber

  const pageSize = route.query.size && route.query.size.toString().trim() !== '0'
    ? parseInt(route.query.size as string)
    : searchParams.pageSize

  const sortBy = route.query.sort
    ? route.query.sort as string
    : searchParams.sortBy

  const orderBy = route.query.order
    ? route.query.order as string
    : searchParams.orderBy

  const facets = route.query.facets
    ? route.query.facets as string
    : ''

  const linkedANDSearchFieldsActive = route.query.ls
    ? (route.query.ls as string).split(',')
    : undefined

  return {
    query,
    pageNumber,
    pageSize,
    sortBy,
    orderBy,
    facets,
    linkedANDSearchFieldsActive,
  }
}

export function updatePublicSearchParams(route: Route, searchParams: PublicSearchParams): PublicSearchParams {
  const mi = route.query.mi
    ? parseInt(route.query.mi as string)
    : searchParams.mi

  const ps = route.query.ps
    ? route.query.ps.toString().trim()
    : searchParams.ps

  const pc = route.query.pc
    ? route.query.pc.toString().trim()
    : searchParams.pc

  const sc = route.query.sc
    ? route.query.sc.toString().trim()
    : searchParams.sc

  const et = route.query.et
    ? route.query.et.toString().trim()
    : searchParams.et

  const mrlistId = route.query.mrlistId
    ? route.query.mrlistId.toString().trim()
    : searchParams.mrlistId

  const page = route.query.page && route.query.page.toString().trim() !== '0'
    ? parseInt(route.query.page as string)
    : searchParams.page

  const size = route.query.size && route.query.size.toString().trim() !== '0'
    ? parseInt(route.query.size as string)
    : searchParams.size

  return {
    mi,
    ps,
    pc,
    sc,
    et,
    mrlistId,
    page,
    size,
  }
}

export function encodeUrl(url:string): string {
  // Encode the URL for use with https://mathscinet.ams.org/mathscinet/leavingmsn?url=
  return url.replace(/&/g, '%26').replace(/;/g, '%3B')
}
