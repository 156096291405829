import { getResult } from '../utils/api'
import { checkInt } from '../utils/type-validation'

export default {
  async details(seriesId) {
    checkInt(seriesId, 'seriesId')

    const result = await getResult('/api/series/details', { seriesId })
    return result.series
  },

  async mcq(seriesId) {
    checkInt(seriesId, 'seriesId')

    return getResult('/api/series/mcq', { seriesId })
  },

  async publications(seriesId) {
    checkInt(seriesId, 'seriesId')

    const result = await getResult('/api/series/publications', { seriesId })
    return result.series
  },

  async classifications(seriesId) {
    checkInt(seriesId, 'seriesId')

    const result = await getResult('/api/series/classifications', { seriesId })
    return result.series
  },

  async authors(seriesId) {
    checkInt(seriesId, 'seriesId')

    const result = await getResult('/api/series/authors', { seriesId })
    return result.series
  },

  async citations(seriesId, year) {
    checkInt(seriesId, 'seriesId')
    try { checkInt(year, 'year') } catch (e) { year = '' }

    const result = await getResult('/api/series/citations', {
      seriesId,
      year,
    })
    return result.series
  },

  async topCiters(seriesId, year) {
    checkInt(seriesId, 'seriesId')
    try { checkInt(year) } catch (e) { year = '' }

    const result = await getResult('/api/series/topCiters', {
      seriesId,
      year,
    })
    return result.series
  },

  async history(seriesId) {
    checkInt(seriesId)

    const result = await getResult('/api/series/history', { seriesId })
    return result
  },

  async volumes(seriesId, currentPage, pageSize, startYear, endYear) {
    checkInt(seriesId)

    const result = await getResult('/api/series/volumes', { seriesId, currentPage, pageSize, startYear, endYear })
    return result.series
  },

  async volumesYears(seriesId) {
    checkInt(seriesId)

    const result = await getResult('/api/series/volumesYears', { seriesId })
    return result.series
  }
}
